import React, { useContext } from 'react';
import { FaGem, FaChartBar, FaArrowUp, FaUsers } from 'react-icons/fa';
import { UserContext } from '../App';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';

const formatDate = (dateString) => {
  if (!dateString) return 'Not available';
  
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'Invalid date';

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString(undefined, options);
};

const getPlanDetails = (userInfo) => {
  if (!userInfo || !userInfo.plan) return null;

  const isPremium = userInfo.plan.startsWith('price_');
  const isCanceling = userInfo.subscriptionStatus === 'canceling';
  let planName = isPremium ? (userInfo.plan === process.env.REACT_APP_STRIPE_PREMIUM_PRICE_ID ? 'Premium' : 'Team') : 'Trial';
  let dateLabel = isPremium ? 'Renews on' : 'Expires on';
  let status = userInfo.subscriptionStatus;

  if (isCanceling) {
    status = 'Ending Soon';
  }

  return {
    planName,
    dateLabel,
    date: userInfo.currentPeriodEnd,
    isPremium,
    isCanceling,
    status,
    priceId: userInfo.plan
  };
};

const PlanDetailsBox = () => {
  const userInfo = useContext(UserContext);
  const planDetails = getPlanDetails(userInfo);

  const handleUpgrade = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.post(
        `${process.env.REACT_APP_USER_API_URL}/create-checkout-session`,
        { priceId: process.env.REACT_APP_STRIPE_PREMIUM_PRICE_ID }, // Default to premium plan
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { sessionId } = response.data;
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Stripe checkout error:', error);
      }
    } catch (error) {
      console.error('Failed to create checkout session:', error);
    }
  };

  if (!planDetails) return null;

  const getPlanIcon = () => {
    if (planDetails.planName === 'Premium') {
      return <FaGem className="h-6 w-6 text-white" />;
    } else if (planDetails.planName === 'Team') {
      return <FaUsers className="h-6 w-6 text-white" />;
    } else {
      return <FaChartBar className="h-6 w-6 text-white" />;
    }
  };

  return (
    <div className="bg-indigo-100 overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-grow">
            <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
              {getPlanIcon()}
            </div>
            <div className="ml-5 flex-grow">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">Plan Details</dt>
                <dd className="text-lg font-medium text-gray-900 capitalize flex items-center">
                  {planDetails.planName} Plan
                  {planDetails.isPremium && (
                    planDetails.planName === 'Premium' ? 
                      <FaGem className="ml-2 text-indigo-600" /> : 
                      <FaUsers className="ml-2 text-indigo-600" />
                  )}
                </dd>
                <dd className={`text-sm ${planDetails.isCanceling ? 'text-red-600 font-semibold' : 'text-gray-600'}`}>
                  Status: {planDetails.status}
                </dd>
                <dd className="text-sm text-gray-600">{planDetails.dateLabel}: {formatDate(planDetails.date)}</dd>
                {planDetails.isCanceling && (
                  <dd className="text-sm text-red-600 mt-2">Your subscription will end on the expiry date.</dd>
                )}
              </dl>
            </div>
          </div>
          {(planDetails.planName === 'Trial' || planDetails.isCanceling) && (
            <button
              onClick={handleUpgrade}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              <FaArrowUp className="mr-2" />
              {planDetails.isCanceling ? 'Reactivate' : 'Upgrade'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanDetailsBox;