import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../App';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';
import { Link } from 'react-router-dom';

function Agents({ user }) {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userInfo = useContext(UserContext);

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAgents(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch agents');
      setLoading(false);
    }
  };

  const deleteAgent = async (agentId) => {
    try {
      const token = await getValidToken();
      await axios.delete(`${process.env.REACT_APP_USER_API_URL}/agents/${agentId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchAgents(); // Refresh the list after deletion
    } catch (err) {
      setError('Failed to delete agent');
    }
  };

  const getPlanLimit = () => {
    if (userInfo.plan === process.env.REACT_APP_STRIPE_PREMIUM_PRICE_ID) {
      return 5;
    } else if (userInfo.plan === process.env.REACT_APP_STRIPE_TEAM_PRICE_ID) {
      return 20;
    }
    return 1; // Default or free plan
  };

  const planLimit = getPlanLimit();
  const canAddAgent = agents.length < planLimit;

  if (loading) return <div>Loading agents...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">Agents</h1>
      <p className="mb-4 text-gray-600">
        You have {agents.length} out of {planLimit} agents available on your current plan.
      </p>
      <table className="w-full border-collapse mb-8">
        <thead>
          <tr className="bg-gray-100">
            <th className="border p-2 text-left w-4/5">Name</th>
            <th className="border p-2 text-center w-1/5">Actions</th>
          </tr>
        </thead>
        <tbody>
          {agents.sort((a, b) => (a.read_only === b.read_only) ? 0 : a.read_only ? -1 : 1).map((agent) => (
            <tr key={agent.id} className={agent.read_only ? 'bg-gray-50' : ''}>
              <td className="border p-2">
                <Link to={`/agents/${agent.id}`} className="text-blue-500 hover:underline">{agent.name}</Link>
              </td>
              <td className="border p-2 text-center">
                <Link to={`/agents/${agent.id}`} className="text-blue-500 mr-2">View</Link>
                {!agent.read_only && (
                  <button 
                    onClick={() => deleteAgent(agent.id)} 
                    className="text-red-500"
                  >
                    Delete
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-center">
        <Link
          to="/agents/add"
          className={`bg-blue-500 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out ${
            canAddAgent ? 'hover:bg-blue-600' : 'opacity-50 cursor-not-allowed'
          }`}
          onClick={(e) => !canAddAgent && e.preventDefault()}
        >
          {canAddAgent ? 'Add a new agent' : 'Agent limit reached'}
        </Link>
      </div>
    </div>
  );
}

export default Agents;