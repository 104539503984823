import React, { useState, useEffect } from 'react';
import { FaPaperPlane, FaSpinner } from 'react-icons/fa';
import FormInput from './FormInput';

function Enterprise({ user }) {
  const [formData, setFormData] = useState({
    subject: 'Enterprise Inquiry',
    name: '',
    companyName: '',
    users: '',
    info: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    if (user && user.signInDetails && user.signInDetails.loginId) {
      setUserEmail(user.signInDetails.loginId);
    }
  }, [user]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const webhookUrl = 'https://longhaired-stirring-strawflower.glitch.me/user-webhooks/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwbGF0Zm9ybSI6InNsYWNrIiwiY2hhbm5lbCI6IkMwNkpBQ1pVVjZKIiwidGVhbUlkIjoiVDlaTlk3MDhIIiwic2xhY2tfY29udm8iOltdLCJpYXQiOjE3MjUzMzgxMzd9.fWKrhtrXy1bgtxL9KAi17C_-TpUNvUJgm8hS7IuhxUU';

    const dataToSubmit = {
      ...formData,
      userEmail: userEmail || 'Not available'
    };

    try {
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSubmit),
      });

      if (response.ok) {
        setIsSubmitted(true);
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="max-w-2xl mx-auto p-6 text-center">
        <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-6 rounded-md shadow-md">
          <p className="font-bold text-xl mb-2">Thank you for reaching out!</p>
          <p>We'll be in touch soon.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Enterprise Inquiry</h1>
      <p className="mb-4">Your email: {userEmail || 'Not available'}</p>
      <form onSubmit={handleSubmit} className="space-y-4">
        <FormInput
          label="Your Name"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <FormInput
          label="Company Name"
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          required
        />
        <FormInput
          label="Number of Users"
          type="number"
          name="users"
          value={formData.users}
          onChange={handleChange}
          required
        />
        <div className="mb-4">
          <label htmlFor="info" className="block text-sm font-medium text-gray-700 mb-1">
            Additional Information
          </label>
          <textarea
            id="info"
            name="info"
            rows="4"
            value={formData.info}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          ></textarea>
        </div>
        <button
          type="submit"
          disabled={isSubmitting}
          className={`flex items-center justify-center w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? (
            <>
              <FaSpinner className="animate-spin mr-2" /> Submitting...
            </>
          ) : (
            <>
              <FaPaperPlane className="mr-2" /> Contact Us
            </>
          )}
        </button>
      </form>
    </div>
  );
}

export default Enterprise;