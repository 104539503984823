import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaGithub, FaNpm, FaEye, FaEyeSlash, FaCopy } from 'react-icons/fa';

function Skills() {
  const [showSecret, setShowSecret] = useState(false);

  const teamId = 'TXXXXX-YYYYYYYYYY';
  const secretKey = 'ZZZZZZZZZ';

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // You might want to add a notification here to inform the user that the text has been copied
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-bold text-gray-800">Agent Skills</h1>
        <Link to="/agents" className="text-blue-500 hover:text-blue-600">Back to Agents</Link>
      </div>
      
      <p className="text-gray-600 mb-6">
        Enhance your agents with custom skills. Choose one of the following options to get started:
      </p>

      <div className="grid md:grid-cols-2 gap-6">
        <div className="border rounded-lg p-6 hover:shadow-lg transition-shadow">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaNpm className="mr-2 text-red-500" /> Option 1: Use our SDK
          </h2>
          <p className="mb-4">
            Install our JavaScript SDK and code your own custom skill:
          </p>
          <pre className="bg-gray-100 p-2 rounded mb-4">
            npm install zapdroid-sdk
          </pre>
          <a 
            href="https://www.npmjs.com/package/zapdroid-sdk" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            View SDK Documentation
          </a>
        </div>

        <div className="border rounded-lg p-6 hover:shadow-lg transition-shadow">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaGithub className="mr-2" /> Option 2: Start with a Template
          </h2>
          <p className="mb-4">
            Fork our starter skill repository and customize it:
          </p>
          <a 
            href="https://github.com/Zapdroid-io/skill-template" 
            target="_blank" 
            rel="noopener noreferrer"
            className="bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-700 transition-colors inline-flex items-center"
          >
            <FaGithub className="mr-2" /> Fork Starter Skill
          </a>
        </div>
      </div>

      <div className="mt-8 border rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">SDK Credentials</h2>
        <div className="space-y-4">
          <div className="flex items-center">
            <span className="font-semibold mr-2">ZAPDROID_TEAM_ID:</span>
            <span className="mr-2">{teamId}</span>
            <button onClick={() => copyToClipboard(teamId)} className="text-blue-500 hover:text-blue-600">
              <FaCopy />
            </button>
          </div>
          <div className="flex items-center">
            <span className="font-semibold mr-2">ZAPDROID_SECRET_KEY:</span>
            <span className="mr-2">{showSecret ? secretKey : '•'.repeat(secretKey.length)}</span>
            <button onClick={() => setShowSecret(!showSecret)} className="mr-2 text-blue-500 hover:text-blue-600">
              {showSecret ? <FaEyeSlash /> : <FaEye />}
            </button>
            <button onClick={() => copyToClipboard(secretKey)} className="text-blue-500 hover:text-blue-600">
              <FaCopy />
            </button>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">Next Steps</h2>
        <ol className="list-decimal list-inside space-y-2">
          <li>Choose one of the options above to create your skill.</li>
          <li>Develop and test your skill locally.</li>
          <li>Once ready, deploy your skill to a hosting platform of your choice.</li>
          <li>Visit this page and you will see your skill in the list.</li>
        </ol>
      </div>

      <p className="mt-6 text-gray-600">
        Need help? Check out our <a href="#" className="text-blue-500 hover:underline">documentation</a> or <a href="#" className="text-blue-500 hover:underline">contact support</a>.
      </p>
    </div>
  );
}

export default Skills;