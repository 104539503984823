import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaServer, FaCheck, FaSave, FaExclamationTriangle, FaSpinner, FaFlask, FaEdit, FaTrash } from 'react-icons/fa';
import FormInput from './FormInput';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

function Mailbox() {
  const [formData, setFormData] = useState({
    imap: { username: '', password: '', hostname: '', port: '', useTLS: false },
    smtp: { username: '', password: '', hostname: '', port: '', useTLS: false },
  });
  const [isValid, setIsValid] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [showTestingHelper, setShowTestingHelper] = useState(false);
  const [mailboxExists, setMailboxExists] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setShowTestingHelper(process.env.NODE_ENV !== 'production');
    checkMailboxExists();
  }, []);

  const checkMailboxExists = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(
        `${process.env.REACT_APP_USER_API_URL}/email/mailbox`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMailboxExists(response.data.exists);
      if (response.data.exists) {
        populateFormData(response.data.mailboxData);
      }
    } catch (error) {
      console.error('Error checking mailbox:', error);
      showNotification("Error", "Failed to check mailbox status", "danger");
    } finally {
      setIsLoading(false);
    }
  };

  const populateFormData = (mailboxData) => {
    setFormData({
      imap: {
        username: mailboxData.imap.auth.user,
        password: '', // Don't populate password
        hostname: mailboxData.imap.host,
        port: mailboxData.imap.port,
        useTLS: mailboxData.imap.secure,
      },
      smtp: {
        username: mailboxData.smtp.auth.user,
        password: '', // Don't populate password
        hostname: mailboxData.smtp.host,
        port: mailboxData.smtp.port,
        useTLS: mailboxData.smtp.secure,
      },
    });
  };

  const handleChange = (section, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: { ...prev[section], [field]: value },
    }));
  };

  const showNotification = (title, message, type) => {
    Store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  };

  const handleValidate = async () => {
    setIsValidating(true);
    setValidationMessage('');

    try {
      const token = await getValidToken();
      const response = await axios.post(
        `${process.env.REACT_APP_USER_API_URL}/email/validate-mailbox`,
        {
          imapSettings: formData.imap,
          smtpSettings: formData.smtp
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setIsValid(response.data.success);
      setValidationMessage(response.data.message);
      showNotification(
        "Validation Result",
        response.data.message,
        response.data.success ? "success" : "danger"
      );
    } catch (error) {
      console.error('Validation error:', error);
      setIsValid(false);
      setValidationMessage(error.response?.data?.message || 'Validation failed. Please try again.');
      showNotification(
        "Validation Error",
        error.response?.data?.message || 'Validation failed. Please try again.',
        "danger"
      );
    } finally {
      setIsValidating(false);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const token = await getValidToken();
      const endpoint = mailboxExists ? '/email/mailbox/update' : '/email/mailbox';
      const response = await axios.post(
        `${process.env.REACT_APP_USER_API_URL}${endpoint}`,
        {
          name: "Default Mailbox",
          email: formData.imap.username,
          imapSettings: {
            auth: {
              user: formData.imap.username,
              pass: formData.imap.password
            },
            host: formData.imap.hostname,
            port: formData.imap.port,
            secure: formData.imap.useTLS
          },
          smtpSettings: {
            auth: {
              user: formData.smtp.username,
              pass: formData.smtp.password
            },
            host: formData.smtp.hostname,
            port: formData.smtp.port,
            secure: formData.smtp.useTLS
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        showNotification("Success", mailboxExists ? "Mailbox updated successfully!" : "Mailbox saved successfully!", "success");
        setMailboxExists(true);
      } else {
        showNotification("Error", "Failed to save mailbox. Please try again.", "danger");
      }
    } catch (error) {
      console.error('Save error:', error);
      showNotification(
        "Error",
        error.response?.data?.message || 'Failed to save mailbox. Please try again.',
        "danger"
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this mailbox? This action cannot be undone.')) {
      try {
        const token = await getValidToken();
        const response = await axios.delete(
          `${process.env.REACT_APP_USER_API_URL}/email/mailbox`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          showNotification("Success", "Mailbox deleted successfully!", "success");
          setMailboxExists(false);
          setFormData({
            imap: { username: '', password: '', hostname: '', port: '', useTLS: false },
            smtp: { username: '', password: '', hostname: '', port: '', useTLS: false },
          });
        } else {
          showNotification("Error", "Failed to delete mailbox. Please try again.", "danger");
        }
      } catch (error) {
        console.error('Delete error:', error);
        showNotification(
          "Error",
          error.response?.data?.message || 'Failed to delete mailbox. Please try again.',
          "danger"
        );
      }
    }
  };

  const fillTestData = () => {
    setFormData({
      imap: {
        username: 'hi@zapdroid.io',
        password: 'YZjTsAE27u9Y4ZS',
        hostname: 'mail.privateemail.com',
        port: 993,
        useTLS: true,  // Changed to true for IMAP
      },
      smtp: {
        username: 'hi@zapdroid.io',
        password: 'YZjTsAE27u9Y4ZS',
        hostname: 'mail.privateemail.com',
        port: 465,
        useTLS: true,  // Changed to true for SMTP
      },
    });
  };

  const renderSection = (section, icon) => (
    <div className="mb-6">
      <h2 className="text-xl font-semibold mb-3 flex items-center">
        {icon} {section.toUpperCase()}
      </h2>
      {['username', 'password', 'hostname', 'port'].map(field => (
        <FormInput
          key={field}
          label={field.charAt(0).toUpperCase() + field.slice(1)}
          type={field === 'password' ? 'password' : field === 'port' ? 'number' : 'text'}
          value={formData[section][field]}
          onChange={(e) => handleChange(section, field, field === 'port' ? parseInt(e.target.value, 10) : e.target.value)}
          name={`${section}-${field}`}
        />
      ))}
      <div className="flex items-center mt-2">
        <input
          type="checkbox"
          id={`${section}-tls`}
          checked={formData[section].useTLS}
          onChange={(e) => handleChange(section, 'useTLS', e.target.checked)}
          className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
        <label htmlFor={`${section}-tls`} className="ml-2 block text-sm text-gray-900">
          Use TLS
        </label>
      </div>
    </div>
  );

  if (isLoading) {
    return <div className="flex justify-center items-center h-64"><FaSpinner className="animate-spin text-4xl text-blue-500" /></div>;
  }

  return (
    <div className="max-w-2xl mx-auto p-6">
      {mailboxExists && (
        <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-6 rounded-md shadow-md">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <FaEdit className="text-blue-500 mr-2" />
              <p className="font-bold">You are editing an existing mailbox configuration.</p>
            </div>
            <button
              onClick={handleDelete}
              className="text-red-500 hover:text-red-700 focus:outline-none"
              title="Delete Mailbox"
            >
              <FaTrash />
            </button>
          </div>
        </div>
      )}

      {showTestingHelper && (
        <div className="bg-purple-100 border-l-4 border-purple-500 text-purple-700 p-4 mb-6 rounded-md shadow-md">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <FaFlask className="text-purple-500 mr-2" />
              <p className="font-bold">Testing Helper</p>
            </div>
            <button
              onClick={fillTestData}
              className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
            >
              Fill Test Data
            </button>
          </div>
        </div>
      )}

      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6 rounded-md shadow-md">
        <div className="flex items-center">
          <FaExclamationTriangle className="text-yellow-500 mr-2" />
          <p className="font-bold">Security considerations</p>
        </div>
        <p className="mt-2">
          If you are not allowed to share mailbox settings because of security reasons, you can use our{' '}
          <Link to="/enterprise" className="text-blue-600 hover:text-blue-800 underline">
            Enterprise plan
          </Link>{' '}
          to host your own mailbox agent.
        </p>
      </div>

      <h1 className="text-2xl font-bold mb-6">{mailboxExists ? 'Edit Mailbox Configuration' : 'Add New Mailbox'}</h1>
      {renderSection('imap', <FaEnvelope className="inline mr-2" />)}
      {renderSection('smtp', <FaServer className="inline mr-2" />)}
      <div className="flex space-x-4">
        <button
          onClick={handleValidate}
          disabled={isValidating}
          className={`flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
            isValidating && 'opacity-50 cursor-not-allowed'
          }`}
        >
          {isValidating ? (
            <FaSpinner className="animate-spin mr-2" />
          ) : (
            <FaCheck className="mr-2" />
          )}
          Validate
        </button>
        <button
          onClick={handleSave}
          disabled={!isValid || isSaving}
          className={`flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 ${
            (!isValid || isSaving) && 'opacity-50 cursor-not-allowed'
          }`}
        >
          {isSaving ? (
            <FaSpinner className="animate-spin mr-2" />
          ) : (
            <FaSave className="mr-2" />
          )}
          {isSaving ? 'Saving...' : (mailboxExists ? 'Update' : 'Save')}
        </button>
      </div>
      {validationMessage && (
        <div className={`mt-4 p-2 rounded ${isValid ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {validationMessage}
        </div>
      )}
    </div>
  );
}

export default Mailbox;