import React, { useState, useEffect } from 'react';
import { FaChrome, FaCopy } from 'react-icons/fa';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';

function ChromeExtension() {
  const [extensionKey, setExtensionKey] = useState('Loading...');
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const fetchExtensionKey = async () => {
      try {
        const token = await getValidToken();
        const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/getChromeExtKey`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setExtensionKey(response.data.chromeExtensionKey);
      } catch (error) {
        console.error('Failed to fetch extension key:', error);
        setExtensionKey('Failed to load key');
      }
    };

    fetchExtensionKey();
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(extensionKey).then(() => {
      setNotification('Extension key copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
      setNotification('Failed to copy extension key');
    });
  };

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
        <FaChrome className="w-8 h-8 mr-2 text-blue-500" />
        Chrome Extension Key
      </h2>
      <div className="bg-gray-100 p-4 rounded-lg flex items-center justify-between">
        <p className="text-lg font-mono">{extensionKey}</p>
        <button 
          onClick={copyToClipboard}
          className="ml-4 p-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors"
          aria-label="Copy extension key"
        >
          <FaCopy className="w-5 h-5" />
        </button>
      </div>
      {notification && (
        <div className="mt-2 text-green-600">{notification}</div>
      )}
    </div>
  );
}

export default ChromeExtension;