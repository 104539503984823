import React, { useState, useContext } from 'react';
import { updateUserAttributes, getCurrentUser, updatePassword } from '@aws-amplify/auth';
import { UserContext } from '../App';

function Settings({ user, refreshUserInfo }) {
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [email, setEmail] = useState(user.signInDetails.loginId);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const userInfo = useContext(UserContext);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleOldPasswordChange = (e) => setOldPassword(e.target.value);
  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);

  const updateEmail = async (newEmail) => {
    try {
      const currentUser = await getCurrentUser();
      if (!currentUser) {
        throw new Error('No authenticated user found');
      }
      
      const result = await updateUserAttributes(currentUser, {
        email: newEmail,
      });
      
      console.log('Update result:', result);
      setMessage('Email update initiated. Please check your new email for verification.');
      setShowEmailModal(false);
    } catch (error) {
      console.error('Error updating email:', error);
      setMessage(`Error updating email: ${error.message}`);
    }
  };

  const updateUserPassword = async () => {
    try {
      await updatePassword({ oldPassword, newPassword });
      setMessage('Password updated successfully.');
      setShowPasswordModal(false);
      setOldPassword('');
      setNewPassword('');
    } catch (error) {
      setMessage(`Error updating password: ${error.message}`);
    }
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">Settings</h1>
      <p className="text-xl text-indigo-600 mb-6">Settings page for {user.username}</p>
      
      {message && (
        <div className="mb-6 p-3 bg-blue-100 text-blue-700 rounded">
          {message}
        </div>
      )}
      
      <div className="space-y-4">
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <span className="text-lg font-medium text-gray-700">Update Email</span>
          <button
            onClick={() => setShowEmailModal(true)}
            className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
          >
            Update
          </button>
        </div>

        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <span className="text-lg font-medium text-gray-700">Change Password</span>
          <button
            onClick={() => setShowPasswordModal(true)}
            className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
          >
            Change
          </button>
        </div>
      </div>

      {showEmailModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-xl">
            <h2 className="text-xl font-bold mb-4">Update Email</h2>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder={user.signInDetails.loginId}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600 mb-4"
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowEmailModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={() => updateEmail(email)}
                className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {showPasswordModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-xl">
            <h2 className="text-xl font-bold mb-4">Change Password</h2>
            <input
              type="password"
              value={oldPassword}
              onChange={handleOldPasswordChange}
              placeholder="Current Password"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600 mb-4"
            />
            <input
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              placeholder="New Password"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600 mb-4"
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowPasswordModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={updateUserPassword}
                className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Settings;