import React from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaSlack, FaGithub, FaTwitter, FaPlug } from 'react-icons/fa';

function IntegrationCard({ icon, title, description, link }) {
  return (
    <Link to={link} className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
      <div className="flex items-center mb-4">
        <div className="text-2xl text-blue-500 mr-3">{icon}</div>
        <h3 className="text-xl font-semibold">{title}</h3>
      </div>
      <p className="text-gray-600">{description}</p>
    </Link>
  );
}

function Integrations() {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">Integrations</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <IntegrationCard
          icon={<FaEnvelope />}
          title="Mailbox"
          description="Configure your email settings to integrate with Zapdroid."
          link="/integrations/mailbox"
        />
        {/* Add more IntegrationCard components here as needed */}
      </div>
    </div>
  );
}

export default Integrations;