import React, { useState, useEffect } from 'react';
import { FaSlack } from 'react-icons/fa';
import { getValidToken } from '../utils/tokenManager';

function Slack() {
  const [teamId, setTeamId] = useState('');
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSlackTeamId();
  }, []);

  const fetchSlackTeamId = async () => {
    setIsLoading(true);
    try {
      const token = await getValidToken();
      const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/getSlackTeamId`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setTeamId(data.teamId || '');
      }
    } catch (error) {
      console.error('Error fetching Slack Team ID:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await getValidToken();
      const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/setSlackTeamId`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ teamId })
      });
      if (response.ok) {
        setNotification({ message: 'Slack Team ID updated successfully', type: 'success' });
      } else {
        throw new Error('Failed to update Slack Team ID');
      }
    } catch (error) {
      console.error('Error updating Slack Team ID:', error);
      setNotification({ message: 'Failed to update Slack Team ID', type: 'error' });
    }

    // Clear notification after 5 seconds
    setTimeout(() => setNotification({ message: '', type: '' }), 5000);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
        <FaSlack className="w-8 h-8 mr-2 text-blue-500" />
        Slack Integration
      </h2>
      {notification.message && (
        <div className={`mb-4 p-2 rounded ${notification.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {notification.message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="max-w-md">
        <div className="mb-4">
          <label htmlFor="teamId" className="block text-sm font-medium text-gray-700">
            Slack Team ID
          </label>
          <input
            type="text"
            id="teamId"
            value={teamId}
            onChange={(e) => setTeamId(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-lg p-2"
            disabled={isLoading}
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          disabled={isLoading}
        >
          {teamId ? 'Update' : 'Submit'}
        </button>
      </form>
    </div>
  );
}

export default Slack;