import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaGem, FaUsers, FaCheck, FaTimes, FaSpinner, FaBuilding, FaRobot } from 'react-icons/fa';
import PlanDetailsBox from './PlanDetailsBox';
import { UserContext, UserDispatchContext } from '../App';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';
import { Store } from 'react-notifications-component';

const plans = [
  {
    name: 'Premium',
    icon: FaGem,
    priceId: process.env.REACT_APP_STRIPE_PREMIUM_PRICE_ID,
    agentLimit: 5,
    features: [
      { name: 'Unlimited Messaging', included: true },
      { name: 'Support for Multiple Users', included: false },
      { name: 'Agents', included: true }
    ]
  },
  {
    name: 'Team',
    icon: FaUsers,
    priceId: process.env.REACT_APP_STRIPE_TEAM_PRICE_ID,
    agentLimit: 20,
    features: [
      { name: 'Unlimited Messaging', included: true },
      { name: 'Support for Multiple Users', included: true },
      { name: 'Agents', included: true }
    ]
  }
];

const Pricing = ({ refreshUserInfo }) => {
  const userInfo = useContext(UserContext);
  const dispatch = useContext(UserDispatchContext);
  const [renderTrigger, setRenderTrigger] = useState(0);
  const [upgrading, setUpgrading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    console.log('UserInfo in Pricing:', userInfo);
    console.log('REACT_APP_STRIPE_PREMIUM_PRICE_ID:', process.env.REACT_APP_STRIPE_PREMIUM_PRICE_ID);
    console.log('REACT_APP_STRIPE_TEAM_PRICE_ID:', process.env.REACT_APP_STRIPE_TEAM_PRICE_ID);
    setRenderTrigger(prev => prev + 1);
  }, [userInfo]);

  const updateUserContext = (newInfo) => {
    dispatch({ type: 'UPDATE_USER', payload: newInfo });
  };

  const handleCancelPlan = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.post(
        `${process.env.REACT_APP_USER_API_URL}/cancel-subscription`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Store.addNotification({
        title: "Success",
        message: "Your subscription has been canceled successfully.",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      setShowCancelModal(false);
      updateUserContext({
        subscriptionStatus: 'canceling',
        cancelAtPeriodEnd: true
      });
    } catch (error) {
      console.error('Error canceling subscription:', error);
      Store.addNotification({
        title: "Error",
        message: `Error canceling subscription: ${error.response?.data?.message || error.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  };

  const handleUpgrade = async (priceId) => {
    setUpgrading(true);
    try {
      const token = await getValidToken();

      if (!userInfo || !token) {
        console.error('User information or token is missing');
        Store.addNotification({
          title: "Error",
          message: "Please log in to upgrade your plan.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        return;
      }

      await axios.post(`${process.env.REACT_APP_USER_API_URL}/upgrade-plan`, 
        { newPriceId: priceId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      refreshUserInfo();
    } catch (error) {
      console.error('Error upgrading plan:', error);
      Store.addNotification({
        title: "Error",
        message: "An error occurred while upgrading. Please try again.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    } finally {
      setUpgrading(false);
    }
  };

  if (!userInfo) {
    return <div>Loading user information...</div>;
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">Pricing Plans</h1>
      <div className="mb-8">
        <PlanDetailsBox />
      </div>
      {userInfo.plan && userInfo.plan.startsWith('price_') && userInfo.subscriptionStatus !== 'canceling' && (
        <div className="mb-8 text-center">
          <button
            onClick={() => setShowCancelModal(true)}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
          >
            Cancel Plan
          </button>
        </div>
      )}
      <div className="grid md:grid-cols-2 gap-8">
        {plans.map(plan => {
          const isCurrentPlan = userInfo.plan === plan.priceId;
          const isPremiumPlan = userInfo.plan === process.env.REACT_APP_STRIPE_PREMIUM_PRICE_ID;
          const isTeamPlan = plan.priceId === process.env.REACT_APP_STRIPE_TEAM_PRICE_ID;
          const isDowngrade = userInfo.plan === process.env.REACT_APP_STRIPE_TEAM_PRICE_ID && 
                              plan.priceId === process.env.REACT_APP_STRIPE_PREMIUM_PRICE_ID;
          
          return (
            <div key={`${plan.name}-${renderTrigger}`} className={`bg-white rounded-lg shadow-lg p-6 ${isCurrentPlan ? 'ring-2 ring-cyan-500' : ''}`}>
              <div className="flex items-center mb-4">
                <plan.icon className="text-cyan-500 mr-2 text-2xl" />
                <h2 className="text-2xl font-semibold text-navy-900">{plan.name} Plan</h2>
              </div>
              <ul className="space-y-3 mb-6">
                {plan.features.map(feature => (
                  <li key={feature.name} className="flex items-center">
                    {feature.included ? (
                      <FaCheck className="text-green-500 mr-2" />
                    ) : (
                      <FaTimes className="text-red-500 mr-2" />
                    )}
                    <span className={feature.included ? 'text-gray-800' : 'text-gray-500'}>
                      {feature.name}
                    </span>
                  </li>
                ))}
                <li className="flex items-center">
                  <FaRobot className="text-cyan-500 mr-2" />
                  <span className="text-gray-800">
                    Up to {plan.agentLimit} Agents
                  </span>
                </li>
              </ul>
              <button 
                className={`w-full font-bold py-2 px-4 rounded transition duration-150 ease-in-out ${
                  isCurrentPlan || isDowngrade
                    ? 'bg-gray-300 text-gray-800 cursor-not-allowed' 
                    : 'bg-cyan-500 hover:bg-cyan-600 text-white'
                } ${upgrading ? 'opacity-75 cursor-not-allowed' : ''}`}
                onClick={() => handleUpgrade(plan.priceId)}
                disabled={isCurrentPlan || isDowngrade || upgrading}
              >
                {upgrading ? (
                  <>
                    <FaSpinner className="inline-block mr-2 animate-spin" />
                    Upgrading...
                  </>
                ) : isCurrentPlan 
                  ? 'Current Plan'
                  : isDowngrade
                  ? 'Not Available'
                  : `Choose ${plan.name}`}
              </button>
            </div>
          );
        })}
      </div>

      {/* Add the Enterprise plan information */}
      <div className="mt-12 bg-gray-100 rounded-lg p-6 text-center">
        <div className="flex justify-center items-center mb-4">
          <FaBuilding className="text-cyan-500 mr-2 text-3xl" />
          <h2 className="text-2xl font-semibold text-navy-900">Enterprise Plan</h2>
        </div>
        <p className="text-gray-700 mb-4">
          Need a custom solution for your large organization? We offer an Enterprise plan tailored to your specific needs.
        </p>
        <Link 
          to="/enterprise" 
          className="inline-block bg-cyan-500 hover:bg-cyan-600 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
        >
          Contact Us for Enterprise
        </Link>
      </div>

      {showCancelModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-xl">
            <h2 className="text-xl font-bold mb-4">Cancel Subscription</h2>
            <p className="mb-4">Are you sure you want to cancel your subscription? This action cannot be undone.</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowCancelModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                No, Keep My Plan
              </button>
              <button
                onClick={handleCancelPlan}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              >
                Yes, Cancel Plan
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pricing;