import React, { useState } from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';
import { useNavigate } from 'react-router-dom';
import { Store } from 'react-notifications-component';

function AddAgent() {
  const [name, setName] = useState('');
  const [prompt, setPrompt] = useState('');
  const [useCodeInterpreter, setUseCodeInterpreter] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await getValidToken();
      const response = await axios.post(
        `${process.env.REACT_APP_USER_API_URL}/agents/`,
        { name, prompt, use_code_interpreter: useCodeInterpreter },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        Store.addNotification({
          title: "Success!",
          message: "Agent created successfully",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        navigate('/agents');
      }
    } catch (error) {
      console.error('Error creating agent:', error);
      Store.addNotification({
        title: "Error!",
        message: error.response?.data?.error || "Failed to create agent",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Add New Agent</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block mb-1">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="prompt" className="block mb-1">Prompt:</label>
          <textarea
            id="prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded"
            rows="4"
          ></textarea>
        </div>
        <div className="flex items-center space-x-4">
          <span className="text-sm font-medium text-gray-900">Use Code Interpreter</span>
          <label className="inline-flex relative items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={useCodeInterpreter}
              onChange={() => setUseCodeInterpreter(!useCodeInterpreter)}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Create Agent
        </button>
      </form>
    </div>
  );
}

export default AddAgent;