import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../App';
import { useLocation } from 'react-router-dom';
import PlanDetailsBox from './PlanDetailsBox';

function Dashboard({ user }) {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const userInfo = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    // Check for session_id in URL
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    if (sessionId) {
      setPaymentStatus('success');
      // You might want to verify the session with your backend here
    }
  }, [location]);

  return (
    <div className="bg-white shadow rounded-lg p-6 relative">
      {paymentStatus === 'success' && (
        <div className="mb-4 p-4 bg-green-100 text-green-700 rounded">
          Payment successful! Your account has been upgraded.
        </div>
      )}
      <h1 className="text-3xl font-bold text-gray-800 mb-4">Dashboard</h1>
      <p className="text-xl text-indigo-600 mb-6">
        Hi, {user.username}!
      </p>
      {userInfo && userInfo.email && (
        <p className="text-md text-gray-600 mb-6">
          Email: {userInfo.email}
        </p>
      )}
      <div className="mt-6">
        <PlanDetailsBox />
      </div>
    </div>
  );
}

export default Dashboard;