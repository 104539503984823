import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getValidToken } from '../utils/tokenManager';
import axios from 'axios';
import AgentFiles from './AgentFiles';
import { FaFolder, FaCode, FaComments } from 'react-icons/fa';

function AgentPage() {
  const { id } = useParams();
  const [agent, setAgent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('files');

  useEffect(() => {
    async function fetchAgent() {
      try {
        const token = await getValidToken();
        const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/agents/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAgent(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch agent');
        setLoading(false);
      }
    }
    fetchAgent();
  }, [id]);

  if (loading) return <div>Loading agent...</div>;
  if (error) return <div>{error}</div>;
  if (!agent) return <div>Agent not found</div>;

  const chatUrl = `${process.env.REACT_APP_ASK_ZAPDROID_URL}?agentId=${id}`;

  const handlePublish = async (path) => {
    try {
      const token = await getValidToken();
      const response = await axios.post(
        `${process.env.REACT_APP_USER_API_URL}/publisher/publish`,
        { agentId: agent.id, path },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      // Handle successful publish (e.g., show a success message, update UI)
      console.log('Directory published:', response.data);
    } catch (error) {
      console.error('Error publishing directory:', error);
      // Handle error (e.g., show error message)
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-bold text-gray-800">{agent.name}</h1>
          <a
            href={chatUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FaComments className="mr-2" />
            Chat with agent
          </a>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-2">Prompt:</h2>
          <p className="text-gray-600">{agent.prompt || 'No prompt set'}</p>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <div className="mb-4 flex">
          <button
            className={`mr-2 px-4 py-2 rounded-t-lg flex items-center ${
              activeTab === 'files' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveTab('files')}
          >
            <FaFolder className="mr-2" />
            Files
          </button>
          <button
            className={`px-4 py-2 rounded-t-lg flex items-center ${
              activeTab === 'embed' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveTab('embed')}
          >
            <FaCode className="mr-2" />
            Embed
          </button>
        </div>

        <div className="border-t border-gray-200 pt-4">
          {activeTab === 'files' && <AgentFiles agentId={id} />}

          {activeTab === 'embed' && (
            <div>
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Embed This Agent</h2>
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <p className="text-yellow-700">
                  <span className="font-bold">Coming Soon!</span> Soon you'll be able to embed this agent on any website.
                </p>
              </div>
              <div className="mt-4 bg-gray-100 p-4 rounded">
                <p className="text-gray-500 italic">Embed code will appear here</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AgentPage;