import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/tokenManager';

function Admin({ user }) {
  const [globalFlags, setGlobalFlags] = useState([]);
  const [userFlag, setUserFlag] = useState({ userId: '', flag: '', value: '' });

  useEffect(() => {
    fetchFeatureFlags();
  }, []);

  const fetchFeatureFlags = async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/admin/feature-flags`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGlobalFlags(response.data);
    } catch (error) {
      console.error('Error fetching feature flags:', error);
    }
  };

  const toggleGlobalFlag = async (flag, value) => {
    try {
      const token = await getValidToken();
      await axios.post(
        `${process.env.REACT_APP_USER_API_URL}/admin/feature-flags/global`,
        { flag, value: value === 'true' ? 'false' : 'true' },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchFeatureFlags();
    } catch (error) {
      console.error('Error toggling global flag:', error);
    }
  };

  const toggleUserFlag = async (e) => {
    e.preventDefault();
    try {
      const token = await getValidToken();
      await axios.post(
        `${process.env.REACT_APP_USER_API_URL}/admin/feature-flags/user`,
        userFlag,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUserFlag({ userId: '', flag: '', value: '' });
    } catch (error) {
      console.error('Error toggling user flag:', error);
    }
  };

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>
      <h2 className="text-xl font-semibold mb-2">Global Feature Flags</h2>
      <ul>
        {globalFlags.map((flag) => (
          <li key={flag.key} className="mb-2">
            {flag.key}:{' '}
            <button
              onClick={() => toggleGlobalFlag(flag.key, flag.value)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
            >
              {flag.value}
            </button>
          </li>
        ))}
      </ul>
      <h2 className="text-xl font-semibold my-4">Toggle User-Specific Flag</h2>
      <form onSubmit={toggleUserFlag} className="space-y-2">
        <input
          type="text"
          placeholder="User ID"
          value={userFlag.userId}
          onChange={(e) => setUserFlag({ ...userFlag, userId: e.target.value })}
          className="border p-2 rounded"
        />
        <input
          type="text"
          placeholder="Flag Name"
          value={userFlag.flag}
          onChange={(e) => setUserFlag({ ...userFlag, flag: e.target.value })}
          className="border p-2 rounded"
        />
        <input
          type="text"
          placeholder="Value (true/false)"
          value={userFlag.value}
          onChange={(e) => setUserFlag({ ...userFlag, value: e.target.value })}
          className="border p-2 rounded"
        />
        <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
          Toggle User Flag
        </button>
      </form>
    </div>
  );
}

export default Admin;